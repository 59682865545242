import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiButton, EuiSpacer } from "@elastic/eui";
import FWChangelog from 'components/Search/FW1440pclSearch';
import NavButtons from "components/Downloads/Outdoor_Cameras/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "IN-8401 WQHD Downloads",
  "path": "/Downloads/Outdoor_Cameras/IN-8401_WQHD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Firmware, WebUI and Documentation Downloads",
  "image": "./P_SearchThumb_IN-8401WQHD_Downloads.png",
  "social": "/images/Search/P_SearchThumb_IN-8401WQHD_Downloads.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title="Download Area - IN-8401 WQHD" dateChanged="2018-09-20" author="Mike Polinowski" tag="INSTAR IP Camera" description="Software Downloads and Usermanuals for your INSTAR Products" image="/images/Search/P_SearchThumb_IN-8401WQHD_Downloads.png" twitter="/images/Search/P_SearchThumb_IN-8401WQHD_Downloads.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Downloadbereich/Aussenkameras/IN-8401_WQHD/" locationFR="/fr/Downloads/Outdoor_Cameras/IN-8401_WQHD/" crumbLabel="IN-8401" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "indoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#indoor-cameras",
        "aria-label": "indoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Indoor Cameras`}</h2>
    <h2 {...{
      "id": "in-8401-2k-wqhd-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8401-2k-wqhd-downloads",
        "aria-label": "in 8401 2k wqhd downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8401 2k+ WQHD Downloads`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#downloads"
        }}>{`Downloads`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#indoor-cameras"
            }}>{`Indoor Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#in-8401-2k-wqhd-downloads"
            }}>{`IN-8401 2k+ WQHD Downloads`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#software-installation-wizard"
            }}>{`Software Installation Wizard`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#instructions"
            }}>{`Instructions`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#quick-installation"
                }}>{`Quick Installation`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#camera-software-updates"
            }}>{`Camera software updates`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#firmware"
                }}>{`Firmware`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#download"
                    }}>{`Download`}</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "software-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-installation-wizard",
        "aria-label": "software installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Installation Wizard`}</h2>
    <p>{`Every INSTAR product comes with an installation CD. The CD contains an installation assistant that guides you through the installation of your new INSTAR product. The installation wizard can also be called directly by clicking on the link below and opening the CD online. The wizard contains all the information and current download links you need for the installation.`}</p>
    <p>{`The software CD includes an HTML setup wizard that guides you through the required settings and displays the current download links for your INSTAR product.`}</p>
    <p><strong parentName="p">{`Quick Installation Wizard`}</strong>{`: The wizard guides you through the most important steps to get your INSTAR product up and running.`}</p>
    <p><strong parentName="p">{`Software Installation`}</strong>{`: You will find download links if your INSTAR product requires additional software. You are guided through all steps after you have completed the download and the installation.`}</p>
    <p><strong parentName="p">{`Firmware Updates`}</strong>{`: INSTAR provides firmware and WebUI updates for its products at regular intervals. Below you will find the latest versions of the firmware and WebUI which you can upload to your INSTAR camera.`}</p>
    <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <EuiButton fill mdxType="EuiButton">Start Installation</EuiButton>
    </OutboundLink>
    <h2 {...{
      "id": "instructions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instructions",
        "aria-label": "instructions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructions`}</h2>
    <h3 {...{
      "id": "quick-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quick-installation",
        "aria-label": "quick installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Installation`}</h3>
    <OutboundLink to="https://wiki.instar.com/dl/IN-8401WQHD/QuickInstallation-1440p-IN-8401WQHD.pdf" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <EuiButton href="https://wiki.instar.com/dl/IN-8401WQHD/QuickInstallation-1440p-IN-8401WQHD.pdf" fill mdxType="EuiButton">Quick Start Guide</EuiButton>
    </OutboundLink>
    <h2 {...{
      "id": "camera-software-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-software-updates",
        "aria-label": "camera software updates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera software updates`}</h2>
    <h3 {...{
      "id": "firmware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#firmware",
        "aria-label": "firmware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Firmware`}</h3>
    <p>{`You will always find here the latest version and all previous versions of the firmware for your INSTAR Camera. If you plan to update the WebUI, make sure that you have installed the latest version of the firmware. In most cases, the new features in the web interface (WebUI) also require the latest firmware. For information on how to update the firmware please look `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/WebUI_And_Firmware_Upgrade/"
      }}>{`Here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Important`}</strong>{`: Unzip the `}{`*`}{` .ZIP file after downloading. Please note that you must first install the latest firmware and then the WebUI.`}</p>
    <h4 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://update.instar.de/download/2k+Series/instar-update-2k.zip"
        }}>{`Download Latest Version`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <FWChangelog mdxType="FWChangelog" />
    {/*
     * What is new in version 1.4.3-1.4.3 [548] ?
       * Improved compatibility with HomeKit Secure Video when using multiple Apple Homekit hubs
       * General bugfixes and improvements in the HomeKit connection
       * Advanced MQTT API (scheduled recording, recording status, push notification)
     <br/>
     * What is new in version 1.4.2-1.4.2 [539] ?
      * HomeKit Secure Video - Enjoy additional HomeKit features with Apple smarthome devices
      * Status LEDs now follow the HomeKit protocol when HomeKit is enabled
      * The required minimum security protocol can now be set for MQTT
      * MQTT now supports encrypted WebSocket connections
      * The SSL certificate has been updated
      * The user roles of the default users are now customizable
      * RTSP stream compatibility for third-party devices has been improved
      * The email subject for the photoseries is now customizable
      * Improved touch compatibility for mobile devices in the object detection settings
      * System startup time has been reduced
      * General security updates
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.4.0-1.4.0 [512] ?
      * The additional IR-LEDs in supported models can now be enabled for the duration of the alarm
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.4.0-1.4.0 [512] ?
      * Privacy area overhaul - It is now possible to draw non-rectangles and up to 8 privacy areas. Please note that the CGI API has changed!
      * Improved touch compatibility for mobile devices in menus with areas
      * User management has been reworked - Up to 10 users can access the camera with their personal accounts
      * Each user is now able to change his personal account setting
      * All active sessions of a user will now be deleted when he changes his current login data
      * Support for camera models 8403 2K+ and 8415 2K+ has been added
      * Active noise reduction has been added and can be set in the audio settings
      * Active echo cancellation has been added and can be set in the audio settings
      * The video codec is now customizable for all available streams
      * The stream with the smallest resolution (Channel 13) has been increased to 720p (HD) and has been set to H264 by default
      * P2P library has been updated - To ensure compatibility with this firmware, it is required to update to the latest version of the Instarvision app.
      * In addidtion to the video stream, alarm information can now be aquired over the WebSocket connection as well iPad compatibility with HomeKit has been improved
      * Issues regarding HomeKit have been fixed
      * A bug that in rare cases caused the FTP upload to fail has been fixed
      * Quality-of-Service (QoS) option has been added to MQTT
      * The maximum length of video files has been fixed to 15 minutes. If recordings are to continue a new video file will be created
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.3.3-1.3.1 [476] ?
      * DDNS compatibility for IN-8401 2K+
     <br/>
     * What is new in version 1.3.2-1.3.1 [464] ?
      * Up to 4 servers can be added to Secure NTP
      * HomeKit & Onvif improvements and bugfixes
      * MQTT translations have been updated
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.3.1-1.3.0 [449] ?
      * Issues regarding HomeKit since v1.3.0-1.3.0 not working on multiple devices simultaneously and not receiving any alarm notifications have been resolved
     <br/>
     * What is new in version 1.3.0-1.3.0 [445] ?
      * H265 Stream (1440p & 360p) support for Google Chrome
      * New "My Account" menu allows each user to edit their account settings
      * Language setting is now part of the individual user accounts
      * Two-Factor Authentication (2FA) has been added as an additional security layer
      * Forgot Password option is available at Login in order to reset the user password
      * The above features require users to set up their Email address in "My Account"
      * Secure NTP (aka NTS/NTPsec) has been added as an alternative to NTP
      * Two more additional alarm server requests can be set up now
      * Alarm server test feature with trigger simulation added
      * WiFi signal display/sorting logic has been fixed
      * HomeKit stability has been improved
      * General bug fixes and improvements
      * We recommend clearing the browser cache after this update!
     EuiSpacer
     <br/>
     * What is new in version 1.2.0-1.2.0 [414] ?
      * Protection against brute force attacks
      * Strict HTTP security headers have been added
      * Object detection menu has been added allowing to set minimum and maximum size and matching thresholds for each type of objects
      * Digital zoom and dragging feature can be enabled via Quick Access Bar
      * Updates & bug fixes in alarm server menu. Alarm triggers can now be individually disabled to prevent the alarm push by certain triggers.
      * The schedules for planned snapshots and recordings have been separated from the master schedule, which acts as parent schedule for all alarm trigger
      * Connections to unprotected Wi-Fi networks are now allowed
      * Non-camera data in the root path of the SD card won't be subject to the automatic cleanup feature that deletes data that exceeded the keeping time.
      * Email photo series has been added
      * RTSP has a separate menu now and the feature can now be disabled if required
      * Test function for alarm server and MQTT (CGI)
      * HomeKit log function has been added (CGI)
      * MQTT & ONVIF updates
      * More languages have been added to the WebUI
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.1.1-1.1.0 [380] ?
      * Stricter password requirements
      * Password rating and tips for improvements
      * Wizard steps have been reordered and includes firmware update
      * Experimental: Min/Max-Size-thresholds for object detection updated (CGI)
      * An email issue has been addressed
      * Bug fix for planned recordings
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.1.0-1.0.5 [367] ?
      * Alarm areas can be individually linked with the PIR
      * An issue with the PIR night mode has been resolved
      * For developers: Alarm & object data are included in the video stream
      * Experimental: Min/Max-Size-thresholds for object detection via CGI
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.0.2-1.0.4 [358] ?
      * A bug in the user management has been addressed
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.0.2-1.0.3 [356] ?
      * Changelog is now available in the update menu
      * A logic issue with the linking of detected objects has been resolved
      * The threshold at which an object will be identified as such is now customizable
      * The background color of privacy areas is now customizable
      * Camera name is now used as the browser page title
      * Improved support with Email service providers
      * Improved stability for P2P connections
      * General bug fixes and improvements
     <br/>
     * What is new in version 1.0.1-1.0.2 [349] ?
      * An issue has been reslved where it is impossible to save alarm server settings with less than 5 query parameters
     <br/>
     * What is new in version 1.0.1-1.0.1 [348] ?
      * WebUI now correctly loads the operator and guest UI
      * A bug in the user management has been addressed
      * Email issue has been addressed
      * HomeKit stability has been improved
      * General bug fixes and improvements
     */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      